import { ICataloguePricePromotion } from '../models/promotions.model';

export interface ICatalogueMetadata {
  page: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  billingCycles: Array<number>;
  commitmentLengths: Array<number>;
}

export interface ICatalogueProduct {
  id: string;
  categoryId: string;
  sku: string;
  name: string;
  description: string;
  tags: CatalogueTags[];
  deprecated: true;
  recursionTerm: number;
  billingTerm: number;
  attributes: Map<CatalogueAttributes, string>;
  rank: number;
}

export enum CatalogueAttributes {
  MaximumQuantity = 'maximum-quantity',
  MinimumQuantity = 'minimum-quantity',
  MsProvisioningId = 'ms-provisioning-id',
  Edition = 'edition',
  Segment = 'segment',
  Unit = 'unit',
  TextAlert = 'text-alert',
  MapsTo = 'MapsTo',
  ExclaimerProduct = 'exclaimer-product-type',

  // Frontend Robot Specific Attributes Dont USE.Dont USE.Dont USE.Dont USE.
  MappedFromLicenseId = 'MappedFrom',
}

export enum CatalogueTags {
  Trial = 'Trial',
  HighRisk = 'HighRisk',
  ActivePromotion = 'ActivePromotion',
  UpcomingPromotion = 'UpcomingPromotion',
  Legacy = 'Legacy',
  ExpiredPromotion = 'ExpiredPromotion',
  Addon = 'Addon',
}

export interface ICataloguePrice {
  productId: string;
  currency: string;
  cost: number;
  sale: number;
  promotion: ICataloguePricePromotion;
  promotionCost: number;
}

export interface ICatalogueResult {
  metadata: ICatalogueMetadata;
  promotions: ICataloguePromotion[];
  results: ICatalogue[];
}

export interface ICataloguePromotion {
  id: string;
  promotionId: string;
  productId: string;
  name: string;
  value: number;
  isAutoApplicable: boolean;
  startDate: Date;
  endDate: Date;
  description: string;
}

export interface ICatalogue {
  product: ICatalogueProduct;
  price: ICataloguePrice;
  promotions: ICataloguePromotion[];
}

export interface IProductFilters {
  includePrice?: boolean;
  validOn?: Date | string;
  promotionId?: string;
  partnerId?: string;
  customerId?: string;
}

export interface ICatalogueFilter {
  pageIndex: number;
  pageSize: number;
  partnerId?: string;
  customerId?: string;
  includePrice?: boolean;
  filter?: CatalogueFilter;
}

export interface CatalogueFilter {
  name?: string;
  searchQuery?: string;
  categoryIds?: string[];
  productIds?: string[];
  SKUs?: string[];
  includeDeprecated?: boolean;
  sortBy?: SortableProperty;
  priceValidOn?: Date | string;
  billingCycles?: number[];
  commitmentLengths?: number[];
  includePromotions?: boolean;
  tags?: CatalogueTags[];
}

export enum SortableProperty {
  Rank = 'Rank',
  Id = 'Id',
}

export interface ICatalogueCart {
  catalogue: ICatalogue;
  quantity: number;
}

export interface ICatalogueCartExport {
  productId: string;
  productName: string;
  productBillingTerm: number;
  productRecursionTerm: number;
  quantity: number;
  categoryId: string;
  categoryName: string;
  productPriceCurrency: string;
  productPricePromotionCost: number;
  productPriceCost: number;
  productPriceSale: number;
  productPricePromotionValue: number;
  productPricePromotionType: string;
  productTags: string;
}
