import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CatalogueAttributes, ICatalogue, ICatalogueFilter, ICatalogueProduct, ICatalogueResult, IProductFilters } from '../models/catalogue.model';
import { ICatalogueCategory } from '../models/categories.model';
import { buildQueryParamsByObject } from '../../shared/functions/util-functions';
import { IPartner } from 'src/modules/partner/models/partner.model';
import { map } from 'rxjs/operators';
import { initiateCatalogueProductsAttributesMap } from 'src/modules/microsoft/shared/helpers/helpers';

@Injectable({
  providedIn: 'root',
})
export class CatalogueService {
  private readonly catalogueAPI: string = environment.catalogueApiUrl;

  constructor(private http: HttpClient) {}

  public getCategories(): Observable<ICatalogueCategory[]> {
    return this.http.get<ICatalogueCategory[]>(this.catalogueAPI + 'Categories');
  }

  public getCatalogueProduct(productId: ICatalogueProduct['id'], filters?: IProductFilters): Observable<ICatalogue> {
    let httpParams;

    if (filters) {
      httpParams = buildQueryParamsByObject(filters);
    }

    return this.http
      .get<ICatalogue>(this.catalogueAPI + 'Products/' + productId, {
        params: httpParams,
      })
      .pipe(
        map((catalogue: ICatalogue) => {
          const textAlert = catalogue.product.attributes[CatalogueAttributes.TextAlert];
          if (textAlert && textAlert.includes('|')) {
            const splitTextAlert = textAlert.split('|');
            if (splitTextAlert[1]) {
              catalogue.product.attributes[CatalogueAttributes.TextAlert] = splitTextAlert[1];
            }
          }
          initiateCatalogueProductsAttributesMap(catalogue.product);
          return catalogue;
        }),
      );
  }

  public getCatalogueProducts(filterSettings: ICatalogueFilter): Observable<ICatalogueResult> {
    const httpParams = buildQueryParamsByObject(filterSettings);
    return this.http
      .get<ICatalogueResult>(this.catalogueAPI + 'Products', {
        params: httpParams,
      })
      .pipe(
        map((catalogue: ICatalogueResult) => {
          for (const result of catalogue.results) {
            if (catalogue.promotions !== undefined) {
              const promotionResult = catalogue.promotions.filter((promo) => promo.productId === result.product.id);
              if (promotionResult) {
                result.promotions = promotionResult;
                for (const promotion of result.promotions) {
                  promotion.startDate = new Date(promotion.startDate);
                  promotion.endDate = new Date(promotion.endDate);
                }
              }
            }
            initiateCatalogueProductsAttributesMap(result.product);
          }
          return catalogue;
        }),
      );
  }

  public getCatalogueProductsByAttribute(attribute: string, partnerId: IPartner['id'], skuList: string[]): Observable<ICatalogue[]> {
    const payload = skuList.map((sku) => {
      return { values: sku };
    });

    const httpParams = buildQueryParamsByObject(payload);

    return this.http
      .get<ICatalogue[]>(this.catalogueAPI + 'ByAttribute/' + attribute + '?partnerId=' + partnerId, {
        params: httpParams,
      })
      .pipe(
        map((catalogue: ICatalogue[]) => {
          for (const result of catalogue) {
            const textAlert = result.product.attributes[CatalogueAttributes.TextAlert];
            if (textAlert && textAlert.includes('|')) {
              const splitTextAlert = textAlert.split('|');
              if (splitTextAlert[1]) {
                result.product.attributes[CatalogueAttributes.TextAlert] = splitTextAlert[1];
              }
            }
            initiateCatalogueProductsAttributesMap(result.product);
          }
          return catalogue;
        }),
      );
  }
}
