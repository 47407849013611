import { CatalogueAttributes, ICatalogueProduct } from 'src/modules/catalogue/models/catalogue.model';

export function convertBillingNumberToString(number: number): string {
  switch (number) {
    case 0:
      return 'One Time';
    case 1:
      return 'Monthly';
    case 12:
      return 'Annual';
    case 24:
      return 'Biennial';
    case 36:
      return 'Triennial';
    default:
      return 'ERROR PARSING BILLING CYCLE';
  }
}

export function getBillingCycleDisplayName(product: ICatalogueProduct): string {
  return convertBillingNumberToString(product.billingTerm);
}

export function convertCommitmentNumberToString(number: number): string {
  switch (number) {
    case 0:
      return 'None';
    case 1:
      return 'P1M';
    case 12:
      return 'P1Y';
    case 24:
      return 'P2Y';
    case 36:
      return 'P3Y';
  }
}

export function convertRecursionTermToString(number: number): string {
  switch (number) {
    case 0:
      return 'None';
    case 1:
      return '1 month';
    case 12:
      return '1 year';
    case 24:
      return '2 years';
    case 36:
      return '3 years';
  }
}

export function getCommitmentDisplayName(product: ICatalogueProduct): string {
  switch (product.recursionTerm) {
    case 0:
      return 'None';
    case 1:
      return '1 month';
    case 12:
      return '1 year';
    case 24:
      return '2 years';
    case 36:
      return '3 years';
    default:
      return 'ERROR PARSING BILLING COMMITMENT';
  }
}

export function convertBillingCycleAndCommitmentString(product: ICatalogueProduct): string {
  const billing: string = getBillingCycleDisplayName(product);
  const commitment: string = getCommitmentDisplayName(product);

  return `Billing Cycle ${billing} - Commitment ${commitment}`;
}

/**
 * This function is used to initiate the attributes map for a product.
 * Since we dont have a DTO for the endpoint, the attributes field is of type Object.
 * @param product The product you want to initiate the attributes map for
 */
export function initiateCatalogueProductsAttributesMap(product: ICatalogueProduct): void {
  if (product.attributes instanceof Map) return;

  const attributes = new Map<CatalogueAttributes, string>();

  for (const attr in product.attributes as object) {
    const key = getEnumValue(attr);
    attributes.set(key, product.attributes[attr]);
  }

  product.attributes = attributes;
}

function getEnumValue(enumString: string): CatalogueAttributes | undefined {
  for (const key in CatalogueAttributes) {
    if (CatalogueAttributes[key as keyof typeof CatalogueAttributes] === enumString) {
      return CatalogueAttributes[key as keyof typeof CatalogueAttributes];
    }
  }
  return undefined;
}
